.dashboard-cont{
    display: flex;
    width: 100%;
    top: 0;
    height: 100%;
    color: white;
    position: relative;
}

.dashboard-page-cont{
    margin: 0 auto;
    right: 0;
    width: 70%;
    padding: 10px 0;
}

.dashboard-page-header{
    color: white;
    margin: auto 0;
}

@keyframes slidein {
    from {
        margin-top: 100%;
    }

    to {
        margin-top: 0%;
    }
}

@media only screen and (max-width: 1400px){
    .dashboard-page-cont{
      min-width: 800px;
    }
}