.navbar-cont{
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid gray;
    min-height: 100vh;
    width: 150px;
}

.navbar-actions{
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.navbar-logout{
    margin: auto;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    background-color: rgb(180, 77, 77);
}

.navbar-tabs{
    color: black;
}

.navbar-item-cont{
    margin: auto;
    padding: 10px 20px;
    display: block;
    color: white;
    text-decoration: none;
}

.navbar-item-cont:hover{
    background-color: rgb(45,55,90);
}

.navbar-item-cont:visited{
    color: white;
}