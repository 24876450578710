.accounts-table{
    width: 100%;
    text-align: left;
}

/* ACCOUNTS PAGE ACTION */
.accounts-action-actions{
    display: flex;
    gap: 10px;
}

.account-action-button{
    border: none;
    padding: 8px 10px;
    background-color: rgb(116, 116, 116);
    font-weight: bold;
    border-radius: 3px;
    color: rgb(243, 243, 243);
}

.account-delete-button{
    border: none;
    padding: 8px 10px;
    background-color: red;
    font-weight: bold;
    border-radius: 3px;
    color: rgb(255, 255, 255);
}