.serial-upload-page
{
    gap:                10px;
    color:              white;
    display:            flex;
    flex-direction:     column;
}

.serial-uploader-cont
{
    display:            flex;
    flex-direction:     column;
}

.serial-upload-button
{
    padding:            10px;
    font-size:          18px;
    border-radius:      5px;
    background-color:   black;
    color:              white;
    cursor:             pointer;
}

.serial-uploader-text-area
{
    min-height:         300px;
    max-width:          100%;
    min-width:          100%;
    padding:            15px;
    font-size:          14px;

    outline:            none;
    box-sizing:         border-box;
    background-color:   black;
    color:              white;
}

.serial-uploader-text-area:active
{
    outline:            none;
}

.serials-cont
{
    gap:                10px;
    display:            flex;
    flex-direction:     column;
}

.serial-item {
    max-width: 100%;
    display: flex;
    gap: 10px;
    color: white;
    align-items: center;
}

.serial-count {
    background-color: black;
    margin: auto 0;
    padding: 10px;
    border-radius: 3px;
}

.serial-info-cont {
    display: flex;
    gap: 5px;
    flex: 1;
    min-width: 0;
}

.serial-id {
    cursor: pointer;
    background-color: black;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 50px;
    width: 100%;
}

.serial-input {
    width: 100%;
    min-width: 50px;
    color: white;
    outline: none;
    border: 2px solid rgb(255, 255, 255);
    background-color: black;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
}

.serial-edit,
.serial-confirm,
.serial-cancel,
.serial-delete {
    margin: auto 0;
    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    border-radius: 3px;
}

.serial-edit {
    background-color: rgb(0, 0, 0);
}

.serial-cancel {
    background-color: rgb(138, 79, 79);
}

.serial-confirm {
    background-color: rgb(94, 184, 69);
}

.serial-delete {
    background-color: rgb(0, 0, 0);
    margin-left: auto;
}