.product-page-cont{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.product-page-header-cont{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.product-page-item{
    font-size: 20px;
    cursor: pointer;
    border: 1px solid rgb(68, 68, 68);
    background-color: black;
    padding: 8px 15px;
    border-radius: 10px;
    color: white;
    text-decoration: none;
}

.product-page-item:visited{
    color: white;
}

.product-table{
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
    width: 100%;
    font-size: 18px;
}

.product-table-body{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.p-t-header{
    justify-content: space-between;
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
}

.p-t-row{
    justify-content: space-between;
    display: flex;
    font-size: 14px;
    width: 100%;
}

.p-t-title{
    width: 50%;
}

.p-t-price{
    width: 15%;
}

.p-t-quantity{
    width: 15%;
}

.p-t-actions-cont{
    width: 20%;
    display: flex;
    gap: 10px;
}

.product-actions-cont{
    display: flex;
    gap: 10px;
}

.product-action-button{
    padding: 5px 8px;
}