.overview-page-cont
{
    display:        flex;
    flex-direction: column;
    width:          80%;
    margin:         auto
}

.overview-store-settings
{

}

.overview-store-item{
    display: flex;
    flex-direction: column;
}

.overview-s-i-header{
    margin: 0;
    color: silver;
}

.overview-s-i-body
{
    display:            flex;
    flex-direction:     column;
    box-sizing:         border-box;
    width:              100%;
    margin:             10px 0;
    border-radius:      2px;
    background-color:   #121212;
}

.overview-s-i-body > *:not(:last-child)
{
    border-bottom: 2px solid var(--website-theme);
}

.overview-s-i-b-item
{
    padding: 10px;
    display: flex;
}

.overview-s-i-b-title
{
    font-size:          16px;
    margin:             auto 0;
    width:              80px;
    padding:            10px 15px;
}

.overview-s-i-b-content
{
    display: flex;
    padding: 10px;
}

.overview-s-i-b-input
{
    margin: auto 0;
    padding: 15px;
    font-size: 16px;
    outline: none;
    border: none;
    color: white;
    background-color: rgb(48, 48, 48);
}

.overview-s-i-b-action
{
    font-size:          16px;
    padding:            10px;
    outline:            none;
    border:             none;
    color:              white;
    background-color:   transparent;
}

.overview-payouts{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.overview-p-section{
    width: 50%;
}

.overview-p-header{
    display: flex;
    gap: 20px;
}

.overview-p-h-name{
    margin: auto 0;
    font-size: 26px;
    font-weight: bold;
}

.overview-p-h-amount{
    margin: auto 0;
    font-size: 20px;
    font-weight: bold;
}

.overview-p-calculations{
    display: flex;
}

.overview-p-c-section
{
    display: flex;
    flex-direction: column;
}