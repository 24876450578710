.order-previews-cont{
    display: flex;
    flex-direction: column;
    padding: 15px;
    box-sizing: border-box;
    border: 1px solid rgb(149, 149, 149);
    border-radius: 10px;
    gap: 10px;
}

.order-light-preview{
    background-color: rgb(56, 52, 88);
}

.order-previews-header{
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    font-size: 18px;
    color: rgb(168, 168, 168);
    font-weight: bold;
}

.order-preview-cont{
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.order-preview-header{
    display: flex;
    justify-content: space-between;
}

.order-preview-info{
    padding: 10px 0 0;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.order-page-cont{
    display: flex;
    gap: 20px;
}

.order-page-buttons{
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.order-page-buttons:disabled{
    color: rgb(210, 210, 210);
}

.order-preview-overview{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.order-preview-products{
    color: rgb(156, 156, 156);
    font-size: 14px;
}

.order-preview-products-list{
    padding: 0;
    list-style: none;
}

.order-preview-products-list-item{
    color: rgb(212, 212, 212);
    font-size: 15px;
}

.order-preview-email{
    font-size: 18px;
    color: rgb(215, 215, 215);
}

.order-preview-action{
    width: 20%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
}

.order-preview-action-button{
    padding: 7px 10px;
    box-sizing: border-box;
    border: 1px solid white;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.order-preview-action-button:hover{
    background-color: cadetblue;
}

.order-preview-order-id{
    font-size: 13px;
    color: rgb(164, 164, 164);
}

.order-preview-order-date{
    margin: auto;
}

.order-preview-order-status{
    font-size: 13px;
    display: flex;
    gap: 10px;
    color: rgb(164, 164, 164);
}

.order-preview-price{
    width: 20%;
}

.order-status{
    padding: 3px 10px;
    border-radius: 5px;
}

.order-revoked{
    background-color: orange;
    color: rgb(0, 0, 0);
}

.order-not-paid{
    background-color: rgb(119, 119, 119);
    color: rgb(255, 255, 255);
}

.order-paid{
    background-color: rgb(66, 155, 10);
    color: rgb(255, 255, 255);
}

.order-serial-modal-cont{
    background-color: rgba(161, 161, 161, 0.7);
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.order-serial-modal{
    margin: 100px auto 0;
    border-radius: 5px;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: black;
    padding: 20px;
}

.order-serial-modal-serials{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.order-serial-modal-close{
    background-color: rgb(213, 83, 83);
    padding: 10px;
    width: 60px;
    text-align: center;
    cursor: pointer;
}

/* ORDER PAGE CSS */
.order-page-info-item-header
{
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid white;
    margin: 10px 0;
    color: rgb(192, 192, 192);
}

.order-page-info-cont
{
    display: flex;
    gap: 30px;
}

.order-page-info-details
{

}

.order-page-info-customer-details
{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.order-page-info-customer-details-info
{
    display: flex;
    gap: 20px;
}

.order-page-info-customer-details-item
{
    display: flex;
    flex-direction: column;
}

.order-page-info-customer-details-header
{
    font-weight: bold;
    color: rgb(208, 208, 208);
    font-size: 14px;
    margin: 5px 0;
}

.order-page-info-customer-details-content
{
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    color: rgb(255, 255, 255);
    background-color: rgb(83, 91, 155, 0.7);
    cursor: pointer;
}

.order-page-info-logs
{
    display: flex;
    flex-direction: column;
}

.order-page-info-product-details{
    
}

/**
ADMIN VIEW SHOPPING CART ITEM COMPONENT CSS
**/
.o-p-s-c-i-cont
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.o-p-s-c-i-header-cont
{
    display: flex;
    justify-content: space-between;
}

.o-p-s-c-i-product-title
{
    margin: auto 0;
}

.o-p-s-c-i-body-cont
{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px;
    background-color: rgb(202, 202, 202);
}

.o-p-s-c-i-serial-cont
{
    display: flex;
    justify-content: space-between;
}

.o-p-s-c-i-serial
{
    padding: 5px;
}

.o-p-s-c-i-claimed-email
{
    padding: 5px;
}