.product-edit-form-cont
{

}

.product-edit-form
{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.product-edit-header-cont
{
    display:            flex;
    justify-content:    space-between;
}

.product-edit-actions-cont
{
    display:            flex;
    gap:                10px;
}

.product-edit-action{
    margin:             auto;
    cursor:             pointer;
    padding:            10px;
    border-radius:      5px;
    border:             none;
    color:              rgb(57, 57, 57);
    background-color:   rgb(222, 222, 222);
}

.product-edit-form-small-items-cont
{
    display:            flex;
    gap:                20px
}

.product-edit-form-price-quantity-cont
{
    display:            flex;
    gap:                20px;
    width:              100%;
}

.product-edit-form-item
{
    display:            flex;
    flex-direction:     column;
    gap:                5px;
    width:              100%;
}

.product-edit-form-item-input
{
    border-radius:      2px;
    border:             none;
    box-sizing:         border-box;
    outline:            none;
    padding:            12px;
    background-color:   rgb(86, 86, 86);
    color:              white;
}

.product-edit-enhanced-description
{
    padding:            10px;
    box-sizing:         border-box;
    background-color:   rgb(86, 86, 86);
    color:              white;
    max-width:          100%;
    min-width:          100%;
    min-height:         150px;
}

.product-edit-max-min{
    gap:                10px;
    display:            flex;
}

.discount-item-header-cont
{
    display:            flex;
    justify-content:    space-between;
}

.add-discount-button
{
    margin:             auto 0;
    border:             none;
    cursor:             pointer;
    padding:            10px;
    border-radius:      5px;
}

.product-edit-discount-item
{
    display:            flex;
    gap:                10px;
}

.product-edit-discount-remove-button
{
    margin-top:         auto;
    padding:            10px;
}