.upload-account-page
{
    margin: auto;
    width: 50%;
}

.upload-account-form{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.upload-account-input-cont{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload-account-input{
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    outline: none;
    color: rgb(216, 216, 216);
    background-color: rgb(69, 69, 69);
    padding: 12px 10px;
}

.upload-account-input:focus{
    border: 1px solid rgb(192, 192, 192);
    outline: none;
}

.upload-account-input::placeholder{
    color: rgb(216, 216, 216);
}

.upload-account-submit{
    padding: 10px;
    cursor: pointer;
}

.upload-account-label{
    font-size: 16px;
}

.upload-account-gamemodes-cont{
    background-color: rgb(69, 69, 69);
    color: rgb(216, 216, 216);
    border-radius: 5px;
    padding: 10px;
}

.upload-account-prename-cont{

}

.upload-account-capes-cont{
    display: flex;
    flex-direction: column;
}

.upload-account-cape-option-cont{
    display: flex;
    flex-direction: column;
}

.upload-account-cape-option{

}

.upload-account-tags{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.upload-account-tag{
    display: flex;
    justify-content: space-between;
}

.upload-account-input-tag-cont{
    display: flex;
    gap: 5px;
}

.upload-account-tag-input{
    border-radius: 5px;
    border: none;
    box-sizing: border-box;
    outline: none;
    color: rgb(216, 216, 216);
    background-color: rgb(69, 69, 69);
    padding: 12px 10px;
    width: 80%;
    display: block;
}

.upload-account-tag-input::placeholder{
    color: rgb(216, 216, 216);
}

.upload-account-tag-button{
    cursor: pointer;
    outline: none;
    border: none;
    color: white;
    width: 20%;
    display: block;
    border-radius: 5px;
    background-color: rgb(155, 155, 155);
}

.g2g-eldorado-cont{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    flex-wrap: wrap;
    gap: 20px;
}

.modal-overlay {
    max-height: 100vh;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(23, 23, 23);
    max-width: 700px;
    display: inline-block;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.modal-content {
    background-color: black;
    border-radius: 5px;
    padding: 15px;
    box-sizing: border-box;
    cursor: pointer;
}

.modal-close {
    margin-top: 15px;
    padding: 8px 16px;
    border: none;
    background: red;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}