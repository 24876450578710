:root{
  --website-theme: #242424;
}

body {
  background-color: var(--website-theme);

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

.alerts-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  gap: 20px;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
  padding: 0 20px;
}

.notif-popup{
  width: fit-content;
  text-align: center;
  min-width: 250px;
  justify-content: right;
  gap: 20px;
  top: 0;
  right: 0;
  border-radius: 3px;
  padding: 10px 0;
  animation-name: slidein;
  animation-duration: 1s;
}

.error-popup{
  background-color: #d56f6c;
  color: #9d1d18;
  border: 1px solid #9d1d18;
}

.warning-popup{
  background-color: #d4936b;
  color: #773810;
  border: 1px solid #773810;
}

.success-popup{
  background-color: #7ec17b;
  color: #0f740a;
  border: 1px solid #0f740a;
}

.notif-popup:hover{
  cursor: pointer;
}